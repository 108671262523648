@import "variable";

@charset "utf-8";
* {
  margin: 0;
  padding: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  background: @main-bg;
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  color: @white;
  font-size: 14px;
  letter-spacing: 1.12px;
}

ins,
em,
i {
  font-style: normal;
  text-decoration: none;
}

li, ol {
  list-style: none;
}

img {
  border: 0 none;
  vertical-align: top;
}

a {
  color: inherit;
  text-decoration: none;
}

input, textarea {
  resize: none;
}

.clearfix:after {
  content: '';
  width: 0;
  display: block;
  clear: both;
}

/*margin*/
.m-t-m {
  margin-top: 12px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-xl {
  margin-right: 60px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-t-xxl {
  margin-top: 80px;
}

.m-b-xxl {
  margin-bottom: 80px;
}

/* padding */
.p-none {
  padding: 0;
}
.p-t-none {
  padding-top: 0 !important;
}
.p-b-xl {
  padding-bottom: 40px;
}

.p-b-xxxl {
  padding-bottom: 100px;
}

.p-b-xxxxl {
  padding-bottom: 200px;
}

.p-b-big {
  padding-bottom: 360px;
}

.p-b-large {
  padding-bottom: 500px;
}

/* BASE */
.circle {
  .border-radius(100%);
}

.hide {
  display: none;
}

/* jPage */
.jPage {
  text-align: center;
  margin-bottom: 60px;
  .pagination {
    display: inline-block;
    overflow: hidden;
    li {
      float: left;
      margin-right: 10px;
      border: 1px solid @white-page-color;
      cursor: pointer;
      > * {
        display: block;
        width: 30px;
        padding: 7px 2px;
        font-size: 12px;
        line-height: 14px;
        .opacity(0.6);
        letter-spacing: 0.96px;
      }
      &.active {
        border: 1px solid @orange;
        cursor: default;
        > * {
          .opacity(1);
          color: @orange;
        }
      }
      &.disabled {
        cursor: default;
      }
      &:first-child, &:last-child {
        text-indent: 10000px;
        > * {
          width: 65px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      &:first-child {
        > * {
          background-image: url("@{images}/icon/ic_prev.png");
        }

      }
      &:last-child {
        > * {
          background-image: url("@{images}/icon/ic_next.png");
        }
      }
      &:hover {
        > * {
          .opacity(1);
        }
      }
      &.disabled:hover {
        > * {
          .opacity(0.6);
        }
      }
    }
  }
}
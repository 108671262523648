.container {
  .article {
    .opacity(0.4);
  }
  .article-para {
    margin-top: 20px;
  }
  .top, .main {
    .title {
      .opacity(0.6);
      font-size: 18px;
      letter-spacing: 1.44px;
    }
  }
  .top {
    .bold {
      font-weight: bold;
    }
    .line {
      width: 100%;
      height: 1px;
      background: @white;
      .opacity(0.1);
      margin-top: 20px;
    }
    .line-red {
      width: 18px;
      height: 3px;
      background: @red;
      position: relative;
      top: -2px;
      margin-bottom: 80px;
    }
  }
  .wrapper.about-us {
    float: none;
    width: 100%;
    padding: 0;
  }
}
@import "variable";

.footer {
  width: 100%;
  min-width: 1180px;
  background-color: @main-color;
  .container {
    width: 1180px;
    margin: auto;
    padding-bottom: 70px;
    line-height: 20px;
  }
  .footer-list {
    position: relative;
    overflow: hidden;
    padding: 57px 0 53px 0;
    li {
      padding: 5px 0;
      float: left;
      margin-right: 100px;
      .opacity(0.8);
      &:hover {
        .opacity(1);
      }
      &.logo {
        float: right;
        padding: 0;
        margin-right: 165px;
        a {
          display: block;
          width: 189px;
          height: 30px;
          background: url("@{images}/icon/logo-game.png") no-repeat 0 0;
        }
        &:hover{
          .opacity(0.8);
        }
      }
    }
    .b-bottom {
      position: absolute;
      display: block;
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
      .opacity(0.1);
      background-color: #fff;
    }
  }
  .copyright {
    text-align: center;
    em {
      display: inline-block;
      padding: 36px 0;
      .opacity(0.4);
    }
  }
}
@main-bg: #0f1622;
@main-color: #010916;

@blue-light: #0E1622;

@white: #fff;
@white-side-border: #1A222D;
@white-radio-border: #666;
@white-page-color: #333;

@black: #000;
@black-mask: #010916;
@orange: #FF8A41;

@red: #FF4141;
@black-bg: #0f1623;
@images: "/web/images";

.opacity(@number) {
  opacity: @number;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=ceil(@number*100));
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=ceil(@number*100));
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=ceil(@number*100));
}

.border-radius(@radius:4px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
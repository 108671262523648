@charset "utf-8";
* {
  margin: 0;
  padding: 0;
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  background: #0f1622;
  font-family: HelveticaNeue, Helvetica, Arial, sans-serif;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1.12px;
}
ins,
em,
i {
  font-style: normal;
  text-decoration: none;
}
li,
ol {
  list-style: none;
}
img {
  border: 0 none;
  vertical-align: top;
}
a {
  color: inherit;
  text-decoration: none;
}
input,
textarea {
  resize: none;
}
.clearfix:after {
  content: '';
  width: 0;
  display: block;
  clear: both;
}
/*margin*/
.m-t-m {
  margin-top: 12px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-xl {
  margin-right: 60px;
}
.m-b-xl {
  margin-bottom: 40px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-xxl {
  margin-top: 80px;
}
.m-b-xxl {
  margin-bottom: 80px;
}
/* padding */
.p-none {
  padding: 0;
}
.p-t-none {
  padding-top: 0 !important;
}
.p-b-xl {
  padding-bottom: 40px;
}
.p-b-xxxl {
  padding-bottom: 100px;
}
.p-b-xxxxl {
  padding-bottom: 200px;
}
.p-b-big {
  padding-bottom: 360px;
}
.p-b-large {
  padding-bottom: 500px;
}
/* BASE */
.circle {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.hide {
  display: none;
}
/* jPage */
.jPage {
  text-align: center;
  margin-bottom: 60px;
}
.jPage .pagination {
  display: inline-block;
  overflow: hidden;
}
.jPage .pagination li {
  float: left;
  margin-right: 10px;
  border: 1px solid #333;
  cursor: pointer;
}
.jPage .pagination li > * {
  display: block;
  width: 30px;
  padding: 7px 2px;
  font-size: 12px;
  line-height: 14px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  letter-spacing: 0.96px;
}
.jPage .pagination li.active {
  border: 1px solid #FF8A41;
  cursor: default;
}
.jPage .pagination li.active > * {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  color: #FF8A41;
}
.jPage .pagination li.disabled {
  cursor: default;
}
.jPage .pagination li:first-child,
.jPage .pagination li:last-child {
  text-indent: 10000px;
}
.jPage .pagination li:first-child > *,
.jPage .pagination li:last-child > * {
  width: 65px;
  background-repeat: no-repeat;
  background-position: center;
}
.jPage .pagination li:first-child > * {
  background-image: url("/web/images/icon/ic_prev.png");
}
.jPage .pagination li:last-child > * {
  background-image: url("/web/images/icon/ic_next.png");
}
.jPage .pagination li:hover > * {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.jPage .pagination li.disabled:hover > * {
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.header-ad {
  width: 100%;
  height: 120px;
  background: #0E1622;
}
.header-ad .header-img {
  position: relative;
  margin: 0 auto;
  width: 1180px;
  height: 120px;
}
.header-ad .header-img img {
  width: 100%;
  height: 100%;
}
.header-ad .header-img .header-off {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 26px;
  height: 26px;
  background: url("/web/images/ad/OFF.png");
}
.header {
  position: relative;
  width: 100%;
  min-width: 1180px;
  background: #010916;
  border-bottom: 2px solid #FF8A41;
}
.header .container {
  width: 1180px;
  margin: 0 auto;
  overflow: hidden;
}
.header .bottom {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  z-index: 1;
  background-image: linear-gradient(-270deg, #FF4141 0%, #FF8A41 100%);
  background: -moz-linear-gradient(-270deg, #FF4141 0%, #FF8A41 100%);
  background: -webkit-gradient(-270deg, #FF4141 0%, #FF8A41 100%);
}
.header .logo {
  padding: 25px 0;
}
.header .logo {
  float: left;
  width: 189px;
  height: 78px;
  background: url("/web/images/icon/logo-game.png") no-repeat 0 center;
}
.header .menu-search {
  float: right;
  overflow: hidden;
}
.header .menu,
.header .search {
  float: left;
}
.header .menu {
  font-weight: 400;
  margin-right: 20px;
}
.header .menu li {
  float: left;
  margin: 20px 0;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
}
.header .menu li a {
  display: block;
  padding: 9px 30px;
  line-height: 20px;
}
.header .menu li:hover,
.header .menu li.active {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.header .search {
  position: relative;
  padding: 21px 0;
}
.header .search input {
  width: 260px;
  padding: 8px 20px;
  line-height: 20px;
  outline: 0;
  border: 0;
  background-color: #0E1622;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  color: #fff;
  letter-spacing: inherit;
}
.header .search button {
  position: absolute;
  top: 21px;
  right: 0;
  text-indent: 1000px;
  outline: 0;
  border: 0;
  padding: 10px 20px 10px 0;
  width: 36px;
  height: 36px;
  background: transparent url("/web/images/icon/ic_search.png") no-repeat 0 center;
  cursor: pointer;
}
.container {
  position: relative;
  width: 1180px;
  margin: auto;
  overflow: hidden;
}
.container .wrapper {
  float: left;
  width: 800px;
  padding: 40px 20px 0 0;
}
.container .crumbs {
  line-height: 20px;
  padding-bottom: 20px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container .crumbs + .main-con {
  padding-top: 0;
}
.container .main-con {
  padding-top: 40px;
}
.container .pic-slider {
  width: 100%;
  background-color: #010916;
  overflow: hidden;
}
.container .pic-slider .unslider {
  position: relative;
  border: 1px solid #1A222D;
}
.container .pic-slider .unslider:hover .unslider-arrow {
  display: block;
}
.container .pic-slider .unslider-box {
  position: relative;
  height: 320px;
  overflow: hidden;
}
.container .pic-slider .unslider-box ul.unslider-list {
  position: absolute;
  overflow: hidden;
  height: 100%;
}
.container .pic-slider .unslider-box ul.unslider-list li {
  height: 100%;
}
.container .pic-slider .unslider-box li {
  position: relative;
  float: left;
}
.container .pic-slider .unslider-box li img {
  width: 100%;
  height: 100%;
}
.container .pic-slider .unslider-box li .title {
  position: absolute;
  height: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 14px;
  line-height: 20px;
}
.container .pic-slider .unslider-box li .title .mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000;
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container .pic-slider .unslider-box li .title em {
  position: absolute;
  padding: 20px;
  bottom: 0;
  left: -1px;
  right: -1px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container .pic-slider .unslider-bottom {
  position: absolute;
  height: 1px;
  bottom: -1px;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #fff;
  opacity: 0.1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
}
.container .pic-slider .unslider-arrow {
  position: absolute;
  display: none;
  width: 50px;
  height: 100px;
  top: 50%;
  margin-top: -50px;
  text-indent: 10000px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.container .pic-slider .unslider-arrow.prev {
  left: 0;
  background-image: url("/web/images/icon/ic_arrow_left.png");
}
.container .pic-slider .unslider-arrow.next {
  right: 0;
  background-image: url("/web/images/icon/ic_arrow_right.png");
}
.container .pic-slider .slider-ad {
  padding: 20px 26px;
  text-align: center;
}
.container .pic-slider .slider-ad img {
  max-width: 100%;
}
.container .pic-slider .unslider-nav {
  position: absolute;
  right: 40px;
  bottom: 24px;
}
.container .pic-slider .unslider-nav ul,
.container .pic-slider .unslider-nav ol {
  margin-right: -10px;
}
.container .pic-slider .unslider-nav li {
  float: left;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 1px solid #fff;
  opacity: 0.2;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  text-indent: 10000px;
  cursor: pointer;
}
.container .pic-slider .unslider-nav li.unslider-active {
  position: relative;
  border: none;
  background-color: #fff;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container .model-title .view-all {
  position: relative;
  float: right;
  margin-top: 14px;
  padding-right: 16px;
  font-size: 12px;
  color: #FF4141;
  cursor: pointer;
}
.container .model-title .view-all i {
  position: absolute;
  top: 3px;
  right: -6px;
  border-style: solid;
  border-width: 4px 6px;
  border-color: transparent transparent transparent #FF4141;
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container .model-title .main,
.container .model-title .vice {
  float: left;
  line-height: 30px;
}
.container .model-title .main {
  font-size: 24px;
  color: #FF8A41;
  letter-spacing: 1.92px;
  background-image: -webkit-linear-gradient(left, #FF4141 0, #FF8A41 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}
.container .model-title .vice {
  opacity: 0.2;
  font-size: 18px;
  color: #fff;
  letter-spacing: 1.44px;
}
.container .sorting {
  padding-bottom: 20px;
  border-bottom: 1px solid #1A222D;
  line-height: 20px;
  overflow: hidden;
}
.container .sorting .name {
  float: left;
  margin-right: 40px;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
}
.container .sorting .tab {
  float: left;
  margin-right: -40px;
}
.container .sorting .tab label {
  position: relative;
  display: inline-block;
  padding-left: 24px;
  margin-right: 40px;
  vertical-align: top;
  cursor: pointer;
}
.container .sorting .tab label input {
  opacity: 0;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  width: 0;
  height: 0;
  visibility: hidden;
}
.container .sorting .tab label input:checked + i:after {
  content: ' ';
  position: absolute;
  width: 4px;
  height: 4px;
  top: 50%;
  left: 50%;
  margin-top: -2px;
  margin-left: -2px;
  background-color: #fff;
  border-radius: 5px;
}
.container .sorting .tab label i {
  position: absolute;
  width: 14px;
  height: 14px;
  border: 1px solid #666;
  border-radius: 14px;
  top: 3px;
  left: 0;
}
.container .sorting .tab label span {
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  line-height: 20px;
  vertical-align: top;
}
.container .movie-activity-list {
  padding-bottom: 60px;
}
.container .movie-activity-list ul {
  margin-right: -20px;
}
.container .channels .movie-activity-list {
  display: none;
}
.container .channels .movie-activity-list.active {
  display: block;
}
.container li.movie-element {
  position: relative;
  float: left;
  width: 380px;
  margin-right: 20px;
  margin-top: 40px;
  cursor: pointer;
}
.container li.movie-element .movie-img {
  position: relative;
  height: 178px;
}
.container li.movie-element .movie-img .mask {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.container li.movie-element .movie-img .mask .m {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -1px;
  background-color: #010916;
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container li.movie-element .movie-img .mask .handel {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  background: url("/web/images/icon/handle.png") no-repeat center;
}
.container li.movie-element .movie-img .mask .detail,
.container li.movie-element .movie-img .mask .movie {
  position: absolute;
  left: 0;
  right: 0;
  height: 50%;
  text-align: center;
}
.container li.movie-element .movie-img .mask .detail i,
.container li.movie-element .movie-img .mask .movie i {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 24px;
  left: 50%;
  margin-left: -15px;
  background-size: contain;
  background: no-repeat center;
}
.container li.movie-element .movie-img .mask .detail em,
.container li.movie-element .movie-img .mask .movie em {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  font-size: 12px;
  line-height: 20px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container li.movie-element .movie-img .mask .detail:hover em,
.container li.movie-element .movie-img .mask .movie:hover em {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.container li.movie-element .movie-img .mask .detail {
  top: 0;
}
.container li.movie-element .movie-img .mask .detail i {
  background-image: url("/web/images/icon/ic_detail.png");
}
.container li.movie-element .movie-img .mask .movie {
  bottom: 0;
}
.container li.movie-element .movie-img .mask .movie i {
  background-image: url("/web/images/icon/ic_play_big.png");
}
.container li.movie-element .movie-img .img-show {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.container li.movie-element .movie-img img {
  max-width: 100%;
}
.container li.movie-element .movie-body {
  padding-top: 12px;
}
.container li.movie-element .movie-body h3,
.container li.movie-element .movie-body p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.container li.movie-element .movie-body .movie-name {
  white-space: nowrap;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container li.movie-element .movie-body .movie-time {
  opacity: 0.2;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
}
.container li.movie-element .media-introduce {
  position: absolute;
  display: none;
  width: 390px;
  height: 178px;
  padding-left: 20px;
  top: 0;
  left: 100%;
  z-index: 2;
}
.container li.movie-element .media-introduce .intro-con {
  height: 100%;
  padding: 18px 12px 0 20px;
  background-color: #010916;
  line-height: 20px;
  overflow: hidden;
}
.container li.movie-element .media-introduce .intro-con .intro-title {
  padding-bottom: 5px;
  padding-right: 35px;
  font-size: 14px;
  font-weight: 400;
  color: #FF8A41;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container li.movie-element .media-introduce .intro-con .intro-time,
.container li.movie-element .media-introduce .intro-con .intro-desc {
  color: #fff;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container li.movie-element .media-introduce .intro-con .intro-time {
  max-height: 20px;
  font-size: 12px;
  letter-spacing: 0.96px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container li.movie-element .media-introduce .intro-con .intro-desc {
  max-height: 60px;
  font-size: 12px;
  letter-spacing: 0.85px;
  overflow: hidden;
}
.container li.movie-element .media-introduce .intro-con .intro-artist {
  display: none;
  position: absolute;
  height: 52px;
  padding: 0 12px 20px 20px;
  bottom: 0;
  left: 20px;
  right: 0;
  overflow: hidden;
}
.container li.movie-element .media-introduce .intro-con .intro-artist .text {
  float: left;
  font-size: 14px;
  padding: 6px 0;
}
.container li.movie-element .media-introduce .intro-con .intro-artist .artist {
  float: right;
  margin-right: -10px;
}
.container li.movie-element .media-introduce .intro-con .intro-artist .artist span {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  margin-right: 10px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  overflow: hidden;
}
.container li.movie-element .media-introduce .intro-con .intro-artist .artist span img {
  max-width: 100%;
}
.container li.movie-element .media-introduce .intro-con .intro-watch-btn {
  position: absolute;
  height: 40px;
  left: 40px;
  right: 12px;
  bottom: 17px;
  padding: 10px 20px;
  background-color: #FF8A41;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
}
.container li.movie-element .media-introduce .intro-con .intro-watch-btn i {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("/web/images/icon/ic_play_small.png") no-repeat 0 0;
  background-size: 100%;
  vertical-align: top;
}
.container li.movie-element .media-introduce .intro-con .intro-watch-btn em {
  display: inline-block;
  margin-left: 2px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.96px;
  text-transform: uppercase;
  vertical-align: top;
}
.container li.movie-element .media-introduce .intro-con .intro-watch-btn:hover {
  background-color: #F8A062;
}
.container li.movie-element .media-introduce .intro-con .intro-watch-btn:active {
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container li.movie-element .media-introduce .score {
  position: absolute;
  top: 18px;
  right: 20px;
  color: #FF4141;
  line-height: 20px;
}
.container li.movie-element .media-introduce:after {
  content: ' ';
  position: absolute;
  left: 2px;
  top: 24px;
  border-style: solid;
  border-color: transparent #010916 transparent transparent;
  border-width: 10px 9px;
}
.container li.movie-element:hover .movie-img .mask {
  display: block;
}
.container li.movie-element:hover .movie-body .movie-name {
  color: #FF8A41;
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.container li.movie-element:hover .media-introduce {
  display: block;
}
.container .ad {
  margin-bottom: 60px;
  text-align: center;
}
.container .ad img {
  width: 100%;
}
.container .movies-channel {
  position: relative;
  padding-top: 36px;
  line-height: 20px;
}
.container .movies-channel ul {
  padding-bottom: 26px;
  margin-left: -40px;
}
.container .movies-channel li {
  position: relative;
  float: left;
  margin-left: 40px;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  font-size: 12px;
  letter-spacing: 0.96px;
  cursor: pointer;
}
.container .movies-channel li.active:after {
  content: ' ';
  position: absolute;
  width: 28px;
  height: 3px;
  bottom: -27px;
  left: 50%;
  margin-left: -14px;
  background-color: #FF4141;
}
.container .movies-channel li:hover,
.container .movies-channel li.active {
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container .movies-channel .channel-bottom {
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
}
.container .ibox {
  position: relative;
  background-color: #010916;
  border: 1px solid #1A222D;
  overflow: hidden;
}
.container .ibox-title {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 20px;
  border-bottom: 1px solid #1A222D;
  overflow: hidden;
}
.container .ibox-title .computer {
  float: left;
  padding: 10px;
  margin-right: 100px;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  cursor: pointer;
}
.container .ibox-title .computer.active,
.container .ibox-title .computer:hover {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.container .ibox-content {
  padding: 40px;
  margin: -1px;
}
.container .ibox-change {
  position: relative;
  overflow: hidden;
}
.container .ibox-ad-game {
  width: 380px;
  height: 350px;
  margin: 0 auto;
}
.container .ibox-ad-game img {
  width: 100%;
}
.container .ibox-ad {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.container .ibox-ad img {
  width: 100%;
}
.container .ibox-c-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  max-height: 530px;
}
.container .ibox-c-bg img {
  display: block;
  width: 100%;
  opacity: 0.1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  vertical-align: top;
}
.container .ibox-c-bg i {
  position: absolute;
  bottom: 0;
  height: 252px;
  left: 0;
  right: 0;
  background: url("/web/images/icon/gradient.png") repeat-x left;
}
.container .detail .ibox {
  margin-bottom: 60px;
}
.container .detail .ibox-title em {
  padding: 20px;
}
.container .detail .poster {
  position: relative;
  z-index: 1;
}
.container .detail .poster img {
  max-width: 100%;
}
.container .detail .poster .imob {
  position: absolute;
  height: 20px;
  top: 0;
  right: 0;
}
.container .detail .poster .imob i {
  display: inline-block;
  width: 42px;
  height: 20px;
  margin-right: 20px;
  background: url("/web/images/icon/ic_imdb.png") no-repeat 0 center;
  vertical-align: top;
}
.container .detail .poster .imob em {
  font-size: 24px;
  color: #FFA041;
  letter-spacing: 1.92px;
  line-height: 22px;
  vertical-align: top;
}
.container .detail .poster .imob .imob-movie {
  margin-right: 5px;
  background: url("/web/images/icon/ic_movie.png") no-repeat 0 center;
}
.container .detail .poster .imob .imob-tv {
  margin-right: 5px;
  background: url("/web/images/icon/ic_tv.png") no-repeat 0 center;
}
.container .detail .poster-top {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #1A222D;
}
.container .detail .poster-top .poster-img {
  position: absolute;
  width: 300px;
  height: 140px;
  top: 0;
  left: 0;
  overflow: hidden;
}
.container .detail .poster-top .poster-img img {
  max-width: 100%;
}
.container .detail .poster-top .poster-body {
  position: relative;
  min-height: 140px;
  margin-left: 340px;
}
.container .detail .poster-top .poster-body .title {
  margin-bottom: 20px;
  color: #FF8A41;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 1.44px;
  overflow: hidden;
}
.container .detail .poster-top .poster-body p {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.96px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container .detail .poster-top .poster-body .time {
  margin-bottom: 10px;
}
.container .detail .poster-top .poster-body .artist {
  color: #FF4141;
}
.container .detail .poster-top .poster-body .watch {
  position: absolute;
  height: 40px;
  padding: 10px 20px;
  bottom: 0;
  left: 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-color: #FF8A41;
  cursor: pointer;
}
.container .detail .poster-top .poster-body .watch i {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("/web/images/icon/ic_play_small.png") no-repeat 0 0;
  background-size: 100%;
  vertical-align: top;
}
.container .detail .poster-top .poster-body .watch em {
  display: inline-block;
  margin-left: 5px;
  line-height: 20px;
  text-transform: uppercase;
  vertical-align: top;
}
.container .detail .poster-top .poster-body .watch:hover {
  background-color: #F8A062;
}
.container .detail .poster-top .poster-body .watch:active {
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container .detail .poster-top .poster-body .watch-right {
  left: auto;
  right: 0;
  background-color: transparent;
  border: 1px solid #fff;
}
.container .detail .poster-top .poster-body .watch-right:hover {
  background-color: transparent;
}
.container .detail .poster-top .poster-body .watch-right:active {
  background-color: transparent;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
}
.container .detail .poster-top .poster-body .trademark {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.container .detail .poster-top .poster-body .trademark .text {
  font-size: 18px;
}
.container .detail .poster-top .poster-body .trademark .mark {
  margin-top: 20px;
  margin-right: -20px;
}
.container .detail .poster-top .poster-body .trademark .mark-icon {
  float: left;
  margin-right: 20px;
}
.container .detail .poster-top .poster-body .trademark img {
  height: 36px;
}
.container .detail .poster-title {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-family: Helvetica-Bold;
  letter-spacing: 0.96px;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
}
.container .detail .poster-des {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.96px;
}
.container .detail .poster-des > * {
  color: rgba(255, 255, 255, 0.4) !important;
}
.container .detail .poster-des h2 {
  margin-bottom: 20px;
  font-size: 14px;
  letter-spacing: 1.12px;
}
.container .detail .poster-bxslider {
  margin-bottom: 40px;
}
.container .detail .poster-bxslider .preview {
  position: relative;
  width: 700px;
  height: 400px;
  margin-bottom: 20px;
  overflow: hidden;
}
.container .detail .poster-bxslider .preview ins {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
}
.container .detail .poster-bxslider .preview ins a {
  display: block;
  padding: 4px 12px 0 16px;
  line-height: 34px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container .detail .poster-bxslider .preview ins a .orange {
  color: #FF8A41;
  text-decoration: underline;
}
.container .detail .poster-bxslider .preview img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
}
.container .detail .poster-bxslider .preview iframe {
  width: 100%;
  height: 400px;
  overflow: hidden;
}
.container .detail .poster-bxslider .bxslider {
  position: relative;
  height: 80px;
  padding: 0 40px;
  overflow: hidden;
}
.container .detail .poster-bxslider .bxslider .viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.container .detail .poster-bxslider .bxslider .bx-wrapper .bx-controls {
  visibility: hidden;
  display: none;
}
.container .detail .poster-bxslider .bxslider .bxslider-list {
  position: absolute;
  height: 80px;
}
.container .detail .poster-bxslider .bxslider .bxslider-list li {
  position: relative;
  width: 140px;
  height: 100%;
  float: left;
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid #1A222D;
}
.container .detail .poster-bxslider .bxslider .bxslider-list li img {
  max-width: 100%;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container .detail .poster-bxslider .bxslider .bxslider-list li:hover img {
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container .detail .poster-bxslider .bxslider .bxslider-list li[data-tag="v"]:after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent url("/web/images/icon/ic_play_small.png") no-repeat center;
}
.container .detail .poster-bxslider .bxslider .controls .bx-prev,
.container .detail .poster-bxslider .bxslider .controls .bx-next {
  position: absolute;
  width: 22px;
  height: 40px;
  top: 20px;
  text-indent: 10000px;
}
.container .detail .poster-bxslider .bxslider .controls .bx-prev {
  left: 0;
  background: url("/web/images/icon/ic_arrow_left.png") no-repeat center;
}
.container .detail .poster-bxslider .bxslider .controls .bx-next {
  right: 0;
  background: url("/web/images/icon/ic_arrow_right.png") no-repeat center;
}
.container .detail .poster-ad {
  margin: 0 -14px;
  text-align: center;
}
.container .detail .poster-ad img {
  max-width: 100%;
}
.container .search .ibox {
  margin-bottom: 20px;
}
.container .search .ibox-content {
  height: 274px;
}
.container .search .search-img {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 300px;
  height: 140px;
  overflow: hidden;
}
.container .search .search-img img {
  max-width: 300px;
}
.container .search .search-body {
  position: relative;
  height: 100%;
  padding-bottom: 20px;
  margin-left: 340px;
}
.container .search .search-body .imob {
  position: absolute;
  height: 20px;
  top: 0;
  right: 0;
}
.container .search .search-body .imob i {
  display: inline-block;
  width: 42px;
  height: 20px;
  margin-right: 20px;
  background: url("/web/images/icon/ic_imdb.png") no-repeat 0 center;
  vertical-align: top;
}
.container .search .search-body .imob em {
  font-size: 24px;
  color: #FF8A41;
  letter-spacing: 1.92px;
  line-height: 22px;
  vertical-align: top;
}
.container .search .search-body .imob .imob-movie {
  margin-right: 5px;
  background: url("/web/images/icon/ic_movie.png") no-repeat 0 center;
}
.container .search .search-body .imob .imob-tv {
  margin-right: 5px;
  background: url("/web/images/icon/ic_tv.png") no-repeat 0 center;
}
.container .search .search-body .title {
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 400;
  color: #FF8A41;
  letter-spacing: 1.44px;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container .search .search-body .time {
  margin-bottom: 10px;
  font-size: 12px;
  letter-spacing: 0.96px;
  line-height: 20px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container .search .search-body .artist {
  margin-bottom: 20px;
  font-size: 12px;
  color: #FF4141;
  letter-spacing: 0.96px;
  line-height: 20px;
}
.container .search .search-body .desc {
  max-height: 60px;
  font-size: 12px;
  letter-spacing: 0.96px;
  line-height: 20px;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  overflow: hidden;
}
.container .search .search-body .more {
  position: absolute;
  width: 105px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url("/web/images/icon/ic_more_details.png") no-repeat 0 center;
}
.container .search .ad {
  margin-bottom: 20px;
}
.container .classification .ibox {
  margin-bottom: 40px;
}
.container .classification .option {
  margin-top: -20px;
  margin-bottom: 20px;
}
.container .classification .option .list {
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #1A222D;
  overflow: hidden;
}
.container .classification .option .list .name {
  position: absolute;
  width: 66px;
  top: 20px;
  left: 0;
  line-height: 20px;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
}
.container .classification .option .list .option-card {
  margin-left: 76px;
  margin-bottom: -16px;
  margin-right: -20px;
}
.container .classification .option .list .option-card li {
  float: left;
  line-height: 20px;
  margin-bottom: 16px;
  margin-right: 20px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  cursor: pointer;
}
.container .classification .option .list .option-card li.active {
  color: #FF8A41;
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container .classification .option .list .option-card li:hover {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.container .classification .classification-ad {
  text-align: center;
  margin: 0 -16px;
}
.container .classification .classification-ad img {
  max-width: 100%;
}
.container .wrapper-side {
  float: left;
  width: 380px;
  padding-top: 80px;
}
.container .wrapper-side .recommend {
  margin-bottom: -80px;
}
.container .wrapper-side .recommend .movie-element {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
  overflow: hidden;
}
.container .wrapper-side .recommend .movie-element .movie-img {
  position: absolute;
  width: 100px;
  height: 142px;
  top: 0;
  left: 0;
  overflow: hidden;
}
.container .wrapper-side .recommend .movie-element .movie-img img {
  max-width: 100%;
}
.container .wrapper-side .recommend .movie-element .movie-intro {
  position: relative;
  padding-bottom: 40px;
  min-height: 142px;
  margin-left: 120px;
}
.container .wrapper-side .recommend .movie-element .movie-intro .title {
  padding-top: 4px;
  padding-bottom: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #FF8A41;
  line-height: 20px;
}
.container .wrapper-side .recommend .movie-element .movie-intro .intro {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.96px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container .wrapper-side .recommend .movie-element .movie-intro .artist {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.96px;
  color: #FF4141;
}
.container .wrapper-side .recommend .movie-element .movie-intro .intro,
.container .wrapper-side .recommend .movie-element .movie-intro .artist {
  max-height: 40px;
  overflow: hidden;
}
.container .wrapper-side .recommend .movie-element .movie-con {
  max-height: 90px;
  padding-top: 10px;
  font-size: 12px;
  letter-spacing: 0.96px;
  line-height: 20px;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  overflow: hidden;
}
.container .wrapper-side .recommend .movie-element .movie-border-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1A222D;
}
.container .wrapper-side .recommend .tv-element {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
  cursor: pointer;
}
.container .wrapper-side .recommend .tv-element .tv-img {
  position: relative;
  height: 156px;
  overflow: hidden;
}
.container .wrapper-side .recommend .tv-element .tv-img .mask,
.container .wrapper-side .recommend .tv-element .tv-img .video-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.container .wrapper-side .recommend .tv-element .tv-img .mask {
  display: none;
  background-color: #010916;
  opacity: 0.3;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
}
.container .wrapper-side .recommend .tv-element .tv-img .video-icon {
  display: none;
  background: url("/web/images/icon/ic_play_small.png") no-repeat center;
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container .wrapper-side .recommend .tv-element .tv-img img {
  max-width: 100%;
}
.container .wrapper-side .recommend .tv-element .tv-body {
  margin-top: 12px;
}
.container .wrapper-side .recommend .tv-element .tv-body .tv-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container .wrapper-side .recommend .tv-element .tv-body .tv-intro {
  margin-top: 4px;
  opacity: 0.2;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
}
.container .wrapper-side .recommend .tv-element .tv-border-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1A222D;
}
.container .wrapper-side .recommend .tv-element:hover .mask,
.container .wrapper-side .recommend .tv-element:hover .video-icon {
  display: block;
}
.container .wrapper-side .recommend .tv-element:hover .tv-body .tv-title {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.container .wrapper-side .ranking {
  margin-bottom: -20px;
  overflow: hidden;
}
.container .wrapper-side .ranking .rank-element-top {
  width: 300px;
  overflow: hidden;
}
.container .wrapper-side .ranking .rank-element-top:nth-child(2) {
  margin-top: 80px;
  margin-bottom: 80px;
}
.container .wrapper-side .ranking .rank-element-top:last-child {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.container .wrapper-side .ranking .rank-element-top:hover .rank-img .mask {
  display: block;
}
.container .wrapper-side .ranking .rank-element-top:hover .game-body .game-title {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.container .wrapper-side .ranking .rank-element-top .rank-img {
  position: relative;
  width: 300px;
  height: 140px;
}
.container .wrapper-side .ranking .rank-element-top .rank-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 100%;
}
.container .wrapper-side .ranking .rank-element-top .rank-img .top-rank {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  background: url("/web/images/icon/ic_tag_o.png") no-repeat;
}
.container .wrapper-side .ranking .rank-element-top .rank-img .mask {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.container .wrapper-side .ranking .rank-element-top .rank-img .mask .m {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: -1px;
  width: 100%;
  height: 100%;
  background-color: #010916;
  opacity: 0.3;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
}
.container .wrapper-side .ranking .rank-element-top .rank-img .mask .handel {
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  background: url("/web/images/icon/handle.png") no-repeat center;
}
.container .wrapper-side .ranking .rank-element-top .game-body {
  margin-top: 12px;
}
.container .wrapper-side .ranking .rank-element-top .game-body .game-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container .wrapper-side .ranking .rank-element-top .game-body .game-intro {
  margin-top: 4px;
  opacity: 0.2;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
}
.container .wrapper-side .ranking .rank-cion {
  float: left;
  width: 20px;
  height: 26px;
  margin-right: 10px;
  background: url("/web/images/icon/ic_tag_db.png") no-repeat;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  text-indent: 0.5px;
}
.container .wrapper-side .ranking .rank-element {
  padding: 20px 0;
  border-top: 1px solid #1A222D;
  cursor: pointer;
}
.container .wrapper-side .ranking .rank-element .rank-body .rank-img {
  float: left;
  width: 100px;
  height: 46px;
}
.container .wrapper-side .ranking .rank-element .rank-body .text {
  font-size: 14px;
  padding: 5px 0;
}
.container .wrapper-side .ranking .rank-element .rank-body .name {
  float: left;
  max-width: 160px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
}
.container .wrapper-side .ranking .rank-element .rank-body .game-intro {
  margin-top: 4px;
  opacity: 0.2;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
}
.container .wrapper-side .ranking .rank-element .rank-body .free {
  color: #FF4141;
  float: right;
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container .wrapper-side .ranking .rank-element .rank-body:hover .name {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.container .wrapper-side .source {
  margin-bottom: -20px;
}
.container .wrapper-side .source .ad-source {
  margin-bottom: 20px;
  border: 1px solid #1A222D;
  max-height: 256px;
}
.container .wrapper-side .source .ad-source .source-img {
  position: relative;
  height: 100px;
  border-bottom: 1px solid #1A222D;
  background-color: #0f1622;
  overflow: hidden;
}
.container .wrapper-side .source .ad-source .source-img img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.container .wrapper-side .source .ad-source .source-body {
  position: relative;
  padding: 20px 40px 34px 40px;
}
.container .wrapper-side .source .ad-source .source-body .text {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 14px;
}
.container .wrapper-side .source .ad-source .source-body .text .name {
  float: left;
  max-width: 165px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  letter-spacing: 0.96px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container .wrapper-side .source .ad-source .source-body .text .free {
  float: right;
  color: #FF4141;
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.container .wrapper-side .source .ad-source .source-body .source-more {
  position: absolute;
  width: 48px;
  height: 14px;
  right: 40px;
  bottom: 20px;
  background: url("/web/images/icon/ic_more.png") no-repeat center;
  cursor: pointer;
}
.container .tab-content .ibox-content {
  display: none;
}
.container .tab-content .ibox-content:first-child {
  display: block;
}
.container .table {
  overflow: hidden;
}
.container .table table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
}
.container .table th {
  display: block;
  text-align: left;
  margin-top: 40px;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 20px;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  letter-spacing: 1.12px;
}
.container .table td {
  display: block;
  vertical-align: middle;
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  cursor: pointer;
}
.container .table td:hover {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.container .table td p {
  float: left;
}
.container .table td .detail {
  width: 133px;
  margin-right: 20px;
}
.container .table td + td {
  margin-top: 10px;
}
.container .table td.name {
  width: 133px;
  max-width: 133px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.container .table td.hover:hover {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.container .empty {
  margin-top: 300px;
  font-size: 24px;
  text-align: center;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
}
.container .letter-space-sm {
  letter-spacing: 0.06px;
}
.container .article {
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
}
.container .article-para {
  margin-top: 20px;
}
.container .top .title,
.container .main .title {
  opacity: 0.6;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  font-size: 18px;
  letter-spacing: 1.44px;
}
.container .top .bold {
  font-weight: bold;
}
.container .top .line {
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 0.1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  margin-top: 20px;
}
.container .top .line-red {
  width: 18px;
  height: 3px;
  background: #FF4141;
  position: relative;
  top: -2px;
  margin-bottom: 80px;
}
.container .wrapper.about-us {
  float: none;
  width: 100%;
  padding: 0;
}
.footer {
  width: 100%;
  min-width: 1180px;
  background-color: #010916;
}
.footer .container {
  width: 1180px;
  margin: auto;
  padding-bottom: 70px;
  line-height: 20px;
}
.footer .footer-list {
  position: relative;
  overflow: hidden;
  padding: 57px 0 53px 0;
}
.footer .footer-list li {
  padding: 5px 0;
  float: left;
  margin-right: 100px;
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.footer .footer-list li:hover {
  opacity: 1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
}
.footer .footer-list li.logo {
  float: right;
  padding: 0;
  margin-right: 165px;
}
.footer .footer-list li.logo a {
  display: block;
  width: 189px;
  height: 30px;
  background: url("/web/images/icon/logo-game.png") no-repeat 0 0;
}
.footer .footer-list li.logo:hover {
  opacity: 0.8;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}
.footer .footer-list .b-bottom {
  position: absolute;
  display: block;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.1;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
  background-color: #fff;
}
.footer .copyright {
  text-align: center;
}
.footer .copyright em {
  display: inline-block;
  padding: 36px 0;
  opacity: 0.4;
  -webkit-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
}

@import "variable";

.container {
  position: relative;
  width: 1180px;
  margin: auto;
  overflow: hidden;
  .wrapper {
    float: left;
    width: 800px;
    padding: 40px 20px 0 0;

  }
  .crumbs {
    line-height: 20px;
    padding-bottom: 20px;
    .opacity(0.6);
    & + .main-con {
      padding-top: 0;
    }
  }
  .main-con {
    padding-top: 40px;
  }
  .pic-slider {
    width: 100%;
    background-color: @main-color;
    overflow: hidden;
    .unslider {
      position: relative;
      border: 1px solid @white-side-border;
      &:hover {
        .unslider-arrow {
          display: block;
        }
      }
    }
    .unslider-box {
      position: relative;
      height: 320px;
      overflow: hidden;
      ul.unslider-list {
        position: absolute;
        overflow: hidden;
        height: 100%;
        li {
          height: 100%;
        }
      }
      li {
        position: relative;
        float: left;
        img {
          width: 100%;
          height: 100%;
        }
        .title {
          position: absolute;
          height: 60px;
          left: 0;
          right: 0;
          bottom: 0;
          font-size: 14px;
          line-height: 20px;
          .mask {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: @black;
            .opacity(0.8)
          }
          em {
            position: absolute;
            padding: 20px;
            bottom: 0;
            left: -1px;
            right: -1px;
            color: #fff;
            .ellipsis();
          }
        }
      }
    }
    .unslider-bottom {
      position: absolute;
      height: 1px;
      bottom: -1px;
      left: 0;
      right: 0;
      z-index: 2;
      background-color: @white;
      .opacity(0.1);
    }
    .unslider-arrow {
      position: absolute;
      display: none;
      width: 50px;
      height: 100px;
      top: 50%;
      margin-top: -50px;
      text-indent: 10000px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      &.prev {
        left: 0;
        background-image: url("@{images}/icon/ic_arrow_left.png");
      }
      &.next {
        right: 0;
        background-image: url("@{images}/icon/ic_arrow_right.png");
      }
    }
    .slider-ad {
      padding: 20px 26px;
      text-align: center;
      img {
        max-width: 100%;
      }
    }
    .unslider-nav {
      position: absolute;
      right: 40px;
      bottom: 24px;
      ul, ol {
        margin-right: -10px;
      }
      li {
        float: left;
        width: 12px;
        height: 12px;
        margin-right: 10px;
        .border-radius(100%);
        border: 1px solid @white;
        .opacity(.2);
        text-indent: 10000px;
        cursor: pointer;
        &.unslider-active {
          position: relative;
          border: none;
          background-color: @white;
          .opacity(.6);
        }
      }
    }
  }
  .model-title {
    .view-all {
      position: relative;
      float: right;
      margin-top: 14px;
      padding-right: 16px;
      font-size: 12px;
      color: @red;
      cursor: pointer;
      i {
        position: absolute;
        top: 3px;
        right: -6px;
        border-style: solid;
        border-width: 4px 6px;
        border-color: transparent transparent transparent @red;
        .opacity(0.8)
      }
    }
    .main, .vice {
      float: left;
      line-height: 30px;
    }
    .main {
      font-size: 24px;
      color: #FF8A41;
      letter-spacing: 1.92px;
      background-image: -webkit-linear-gradient(left, @red 0, @orange 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-transform: uppercase;
    }
    .vice {
      opacity: 0.2;
      font-size: 18px;
      color: @white;
      letter-spacing: 1.44px;
    }
  }
  .sorting {
    padding-bottom: 20px;
    border-bottom: 1px solid @white-side-border;
    line-height: 20px;
    overflow: hidden;
    .name {
      float: left;
      margin-right: 40px;
      .opacity(0.4);
    }
    .tab {
      float: left;
      margin-right: -40px;
      label {
        position: relative;
        display: inline-block;
        padding-left: 24px;
        margin-right: 40px;
        vertical-align: top;
        cursor: pointer;
        input {
          .opacity(0);
          width: 0;
          height: 0;
          visibility: hidden;
          &:checked + i:after {
            content: ' ';
            position: absolute;
            width: 4px;
            height: 4px;
            top: 50%;
            left: 50%;
            margin-top: -2px;
            margin-left: -2px;
            background-color: @white;
            border-radius: 5px;
          }
        }
        i {
          position: absolute;
          width: 14px;
          height: 14px;
          border: 1px solid @white-radio-border;
          border-radius: 14px;
          top: 3px;
          left: 0;
        }
        span {
          .opacity(0.6);
          line-height: 20px;
          vertical-align: top;
        }
      }
    }
  }
  .movie-activity-list {
    padding-bottom: 60px;
    ul {
      margin-right: -20px;
    }
  }
  .channels {
    .movie-activity-list {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  li.movie-element {
    position: relative;
    float: left;
    width: 380px;
    margin-right: 20px;
    margin-top: 40px;
    cursor: pointer;
    .movie-img {
      position: relative;
      height: 178px;
      .mask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .m {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: -1px;
          background-color: #010916;
          .opacity(0.8);
        }
        .handel {
          position: relative;
          z-index: 1;
          display: block;
          width: 100%;
          height: 100%;
          background: url("@{images}/icon/handle.png") no-repeat center;
        }
        .detail, .movie {
          position: absolute;
          left: 0;
          right: 0;
          height: 50%;
          text-align: center;
          i {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 24px;
            left: 50%;
            margin-left: -15px;
            background-size: contain;
            background: no-repeat center;
          }
          em {
            position: absolute;
            top: 64px;
            left: 0;
            right: 0;
            font-size: 12px;
            line-height: 20px;
            .opacity(0.6)
          }
          &:hover {
            em {
              .opacity(1);
            }
          }
        }
        .detail {
          top: 0;
          i {
            background-image: url("@{images}/icon/ic_detail.png");
          }
        }
        .movie {
          bottom: 0;
          i {
            background-image: url("@{images}/icon/ic_play_big.png");
          }
        }
      }
      .img-show {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      img {
        max-width: 100%;
      }
    }
    .movie-body {
      padding-top: 12px;
      h3, p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
      .movie-name {
        white-space: nowrap;
        height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        .opacity(0.6);
      }
      .movie-time {
        .opacity(0.2);
      }
    }
    .media-introduce {
      position: absolute;
      display: none;
      width: 390px;
      height: 178px;
      padding-left: 20px;
      top: 0;
      left: 100%;
      z-index: 2;
      .intro-con {
        height: 100%;
        padding: 18px 12px 0 20px;
        background-color: @black-mask;
        line-height: 20px;
        overflow: hidden;
        .intro-title {
          padding-bottom: 5px;
          padding-right: 35px;
          font-size: 14px;
          font-weight: 400;
          color: @orange;
          .ellipsis();
        }
        .intro-time, .intro-desc {
          color: @white;
          .opacity(0.6);
        }
        .intro-time {
          max-height: 20px;
          font-size: 12px;
          letter-spacing: 0.96px;
          margin-bottom: 10px;
          .ellipsis();
        }
        .intro-desc {
          max-height: 60px;
          font-size: 12px;
          letter-spacing: 0.85px;
          overflow: hidden;
        }
        .intro-artist {
          display: none;
          position: absolute;
          height: 52px;
          padding: 0 12px 20px 20px;
          bottom: 0;
          left: 20px;
          right: 0;
          overflow: hidden;
          .text {
            float: left;
            font-size: 14px;
            padding: 6px 0;
          }
          .artist {
            float: right;
            margin-right: -10px;
            span {
              display: block;
              float: left;
              width: 32px;
              height: 32px;
              margin-right: 10px;
              .border-radius(32px);
              overflow: hidden;
              img {
                max-width: 100%;
              }
            }
          }
        }
        .intro-watch-btn {
          position: absolute;
          height: 40px;
          left: 40px;
          right: 12px;
          bottom: 17px;
          padding: 10px 20px;
          background-color: @orange;
          .border-radius(4px);
          text-align: center;
          i {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url("/web/images/icon/ic_play_small.png") no-repeat 0 0;
            background-size: 100%;
            vertical-align: top;
          }
          em {
            display: inline-block;
            margin-left: 2px;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.96px;
            text-transform: uppercase;
            vertical-align: top;
          }
          &:hover {
            background-color: #F8A062;
          }
          &:active {
            .opacity(0.8);
          }
        }
      }
      .score {
        position: absolute;
        top: 18px;
        right: 20px;
        color: @red;
        line-height: 20px;
      }
      &:after {
        content: ' ';
        position: absolute;
        left: 2px;
        top: 24px;
        border-style: solid;
        border-color: transparent @black-mask transparent transparent;
        border-width: 10px 9px;
      }
    }
    &:hover {
      .movie-img {
        .mask {
          display: block;
        }
      }
      .movie-body {
        .movie-name {
          color: @orange;
          .opacity(1);
        }
      }
      .media-introduce {
        display: block;
      }
    }
  }
  .ad {
    margin-bottom: 60px;
    text-align: center;
    img {
      width: 100%;
    }
  }
  .movies-channel {
    position: relative;
    padding-top: 36px;
    line-height: 20px;
    ul {
      padding-bottom: 26px;
      margin-left: -40px;
    }
    li {
      position: relative;
      float: left;
      margin-left: 40px;
      .opacity(0.4);
      font-size: 12px;
      letter-spacing: 0.96px;
      cursor: pointer;
      &.active:after {
        content: ' ';
        position: absolute;
        width: 28px;
        height: 3px;
        bottom: -27px;
        left: 50%;
        margin-left: -14px;
        background-color: @red;
      }
      &:hover, &.active {
        .opacity(0.8);
      }
    }

    .channel-bottom {
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: @white;
      .opacity(0.1);
    }
  }
  .ibox {
    position: relative;
    background-color: @main-color;
    border: 1px solid @white-side-border;
    overflow: hidden;
  }
  .ibox-title {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 20px;
    border-bottom: 1px solid @white-side-border;
    overflow: hidden;
    .computer {
      float: left;
      padding: 10px;
      margin-right: 100px;
      .opacity(0.4);
      cursor: pointer;
      &.active, &:hover {
        .opacity(1);
      }
    }
  }
  .ibox-content {
    padding: 40px;
    margin: -1px;
  }
  .ibox-change {
    position: relative;
    overflow: hidden;
  }
  .ibox-ad-game {
    width:380px;
    height:350px;
    margin:0 auto;
    img {
      width: 100%;
    }
  }
  .ibox-ad {
    width:300px;
    height:250px;
    margin:0 auto;
    img {
      width: 100%;
    }
  }
  .ibox-c-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    max-height: 530px;
    img {
      display: block;
      width: 100%;
      .opacity(0.1);
      vertical-align: top;
    }
    i {
      position: absolute;
      bottom: 0;
      height: 252px;
      left: 0;
      right: 0;
      background: url("@{images}/icon/gradient.png") repeat-x left;
    }
  }
  .detail {
    .ibox {
      margin-bottom: 60px;
    }
    .ibox-title em {
      padding: 20px;
    }
    .poster {
      position: relative;
      z-index: 1;
      img {
        max-width: 100%;
      }
      .imob {
        position: absolute;
        height: 20px;
        top: 0;
        right: 0;
        i {
          display: inline-block;
          width: 42px;
          height: 20px;
          margin-right: 20px;
          background: url("@{images}/icon/ic_imdb.png") no-repeat 0 center;
          vertical-align: top;
        }
        em {
          font-size: 24px;
          color: #FFA041;
          letter-spacing: 1.92px;
          line-height: 22px;
          vertical-align: top;
        }
      }
      .imob {
        .imob-movie {
          margin-right: 5px;
          background: url("@{images}/icon/ic_movie.png") no-repeat 0 center;
        }
        .imob-tv {
          margin-right: 5px;
          background: url("@{images}/icon/ic_tv.png") no-repeat 0 center;
        }
      }
    }
    .poster-top {
      position: relative;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid @white-side-border;
      .poster-img {
        position: absolute;
        width: 300px;
        height: 140px;
        top: 0;
        left: 0;
        overflow: hidden;
        img {
          max-width: 100%;
        }
      }
      .poster-body {
        position: relative;
        min-height: 140px;
        margin-left: 340px;
        .title {
          margin-bottom: 20px;
          color: @orange;
          font-size: 18px;
          font-weight: normal;
          letter-spacing: 1.44px;
          overflow: hidden;
        }
        p {
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.96px;
          .opacity(0.6);
        }
        .time {
          margin-bottom: 10px;
        }
        .artist {
          color: @red;
        }
        .watch {
          position: absolute;
          height: 40px;
          padding: 10px 20px;
          bottom: 0;
          left: 0;
          .border-radius(20px);
          background-color: @orange;
          cursor: pointer;
          i {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: url("@{images}/icon/ic_play_small.png") no-repeat 0 0;
            background-size: 100%;
            vertical-align: top;
          }
          em {
            display: inline-block;
            margin-left: 5px;
            line-height: 20px;
            text-transform: uppercase;
            vertical-align: top;
          }
          &:hover {
            background-color: #F8A062;
          }
          &:active {
            .opacity(0.8);
          }
        }
        .watch-right {
          left: auto;
          right: 0;
          background-color: transparent;
          border: 1px solid @white;
          &:hover {
            background-color: transparent;
          }
          &:active {
            background-color: transparent;
            .opacity(0.4);
          }
        }
        .trademark {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          .text {
            font-size: 18px;
          }
          .mark {
            margin-top: 20px;
            margin-right: -20px;
          }
          .mark-icon {
            float: left;
            margin-right: 20px;
          }
          img {
            height: 36px;
          }
        }
      }
    }

    .poster-title {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 20px;
      font-family: Helvetica-Bold;
      letter-spacing: 0.96px;
      .opacity(0.4);
    }
    .poster-des {
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.96px;
      > * {
        color: rgba(255, 255, 255, .4) !important;
      }
      h2 {
        margin-bottom: 20px;
        font-size: 14px;
        letter-spacing: 1.12px;
      }
    }
    .poster-bxslider {
      margin-bottom: 40px;
      .preview {
        position: relative;
        width: 700px;
        height: 400px;
        margin-bottom: 20px;
        overflow: hidden;
        ins {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background-color: @black;
          a {
            display: block;
            padding: 4px 12px 0 16px;
            line-height: 34px;
            .ellipsis();
            .orange {
              color: @orange;
              text-decoration: underline;
            }
          }
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 100%;
        }
        iframe {
          width: 100%;
          height: 400px;
          overflow: hidden;
        }
      }
      .bxslider {
        position: relative;
        height: 80px;
        padding: 0 40px;
        overflow: hidden;
        .viewport {
          overflow: hidden;
          position: relative;
          height: 100%;
        }
        .bx-wrapper {
          .bx-controls {
            visibility: hidden;
            display: none;
          }
        }
        .bxslider-list {
          position: absolute;
          height: 80px;
          li {
            position: relative;
            width: 140px;
            height: 100%;
            float: left;
            margin-right: 20px;
            cursor: pointer;
            border: 1px solid @white-side-border;
            img {
              max-width: 100%;
              .opacity(0.6);
            }
            &:hover {
              img {
                .opacity(0.8);
              }
            }
            &[data-tag="v"] {
              &:after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: transparent url("@{images}/icon/ic_play_small.png") no-repeat center;
              }
            }
          }
        }
        .controls {
          .bx-prev, .bx-next {
            position: absolute;
            width: 22px;
            height: 40px;
            top: 20px;
            text-indent: 10000px;
          }
          .bx-prev {
            left: 0;
            background: url("@{images}/icon/ic_arrow_left.png") no-repeat center;
          }
          .bx-next {
            right: 0;
            background: url("@{images}/icon/ic_arrow_right.png") no-repeat center;
          }
        }
      }
    }
    .poster-ad {
      margin: 0 -14px;
      text-align: center;
      img {
        max-width: 100%;
      }
    }
  }
  .search {
    .ibox {
      margin-bottom: 20px;
    }
    .ibox-content {
      height: 274px;
    }
    .search-img {
      position: absolute;
      top: 40px;
      left: 40px;
      width: 300px;
      height: 140px;
      overflow: hidden;
      img {
        max-width: 300px;
      }
    }
    .search-body {
      position: relative;
      height: 100%;
      padding-bottom: 20px;
      margin-left: 340px;
      .imob {
        position: absolute;
        height: 20px;
        top: 0;
        right: 0;
        i {
          display: inline-block;
          width: 42px;
          height: 20px;
          margin-right: 20px;
          background: url("@{images}/icon/ic_imdb.png") no-repeat 0 center;
          vertical-align: top;
        }
        em {
          font-size: 24px;
          color: @orange;
          letter-spacing: 1.92px;
          line-height: 22px;
          vertical-align: top;
        }
      }
      .imob {
        .imob-movie {
          margin-right: 5px;
          background: url("@{images}/icon/ic_movie.png") no-repeat 0 center;
        }
        .imob-tv {
          margin-right: 5px;
          background: url("@{images}/icon/ic_tv.png") no-repeat 0 center;
        }
      }
      .title {
        margin-bottom: 18px;
        font-size: 18px;
        font-weight: 400;
        color: @orange;
        letter-spacing: 1.44px;
        line-height: 26px;
        .ellipsis();
      }
      .time {
        margin-bottom: 10px;
        font-size: 12px;
        letter-spacing: 0.96px;
        line-height: 20px;
        .opacity(0.6);
      }
      .artist {
        margin-bottom: 20px;
        font-size: 12px;
        color: @red;
        letter-spacing: 0.96px;
        line-height: 20px;
      }
      .desc {
        max-height: 60px;
        font-size: 12px;
        letter-spacing: 0.96px;
        line-height: 20px;
        .opacity(0.4);
        overflow: hidden;
      }
      .more {
        position: absolute;
        width: 105px;
        height: 20px;
        bottom: 0;
        right: 0;
        background: url("@{images}/icon/ic_more_details.png") no-repeat 0 center;
      }
    }
    .ad {
      margin-bottom: 20px;
    }
  }
  .classification {
    .ibox {
      margin-bottom: 40px;
    }
    .option {
      margin-top: -20px;
      margin-bottom: 20px;
      .list {
        position: relative;
        padding: 20px 0;
        border-bottom: 1px solid @white-side-border;
        overflow: hidden;
        .name {
          position: absolute;
          width: 66px;
          top: 20px;
          left: 0;
          line-height: 20px;
          .opacity(0.4);
        }
        .option-card {
          margin-left: 76px;
          margin-bottom: -16px;
          margin-right: -20px;
          li {
            float: left;
            line-height: 20px;
            margin-bottom: 16px;
            margin-right: 20px;
            .opacity(0.6);
            cursor: pointer;
            &.active {
              color: @orange;
              .opacity(0.8);
            }
            &:hover {
              .opacity(1);
            }
          }
        }
      }
    }
    .classification-ad {
      text-align: center;
      margin: 0 -16px;
      img {
        max-width: 100%;
      }
    }
  }
  .wrapper-side {
    float: left;
    width: 380px;
    padding-top: 80px;
    .recommend {
      margin-bottom: -80px;
      .movie-element {
        position: relative;
        padding-bottom: 40px;
        margin-bottom: 40px;
        overflow: hidden;
        .movie-img {
          position: absolute;
          width: 100px;
          height: 142px;
          top: 0;
          left: 0;
          overflow: hidden;
          img {
            max-width: 100%;
          }
        }
        .movie-intro {
          position: relative;
          padding-bottom: 40px;
          min-height: 142px;
          margin-left: 120px;
          .title {
            padding-top: 4px;
            padding-bottom: 6px;
            font-size: 14px;
            font-weight: 400;
            color: @orange;
            line-height: 20px;
          }
          .intro {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.96px;
            .opacity(0.6);
          }
          .artist {
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.96px;
            color: @red;
          }
          .intro, .artist {
            max-height: 40px;
            overflow: hidden;
          }
        }
        .movie-con {
          max-height: 90px;
          padding-top: 10px;
          font-size: 12px;
          letter-spacing: 0.96px;
          line-height: 20px;
          .opacity(0.4);
          overflow: hidden;
        }
        .movie-border-bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          background-color: @white-side-border;
        }
      }
      .tv-element {
        position: relative;
        padding-bottom: 40px;
        margin-bottom: 40px;
        cursor: pointer;
        .tv-img {
          position: relative;
          height: 156px;
          overflow: hidden;
          .mask, .video-icon {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
          .mask {
            display: none;
            background-color: @black-mask;
            .opacity(0.3);
          }
          .video-icon {
            display: none;
            background: url("@{images}/icon/ic_play_small.png") no-repeat center;
            .opacity(0.8);
          }
          img {
            max-width: 100%;
          }
        }
        .tv-body {
          margin-top: 12px;
          .tv-title {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            .opacity(0.6);
          }
          .tv-intro {
            margin-top: 4px;
            .opacity(0.2);
          }
        }
        .tv-border-bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 1px;
          background-color: @white-side-border;
        }
        &:hover {
          .mask, .video-icon {
            display: block;
          }
          .tv-body {
            .tv-title {
              .opacity(1);
            }
          }
        }
      }
    }
    .ranking {
      margin-bottom: -20px;
      overflow: hidden;
      .rank-element-top {
        width: 300px;
        overflow: hidden;
        &:nth-child(2) {
          margin-top: 80px;
          margin-bottom: 80px;
        }
        &:last-child {
          padding-bottom: 40px;
          border-bottom: 1px solid rgba(255, 255, 255, .1);
        }
        &:hover {
          .rank-img {
            .mask {
              display: block;
            }
          }
          .game-body {
            .game-title {
              .opacity(1);
            }
          }
        }
        .rank-img {
          position: relative;
          width: 300px;
          height: 140px;
          img {
            position: absolute;
            left: 0;
            top: 0;
            left: 0;
            bottom: 0;
            max-width: 100%;
          }
          .top-rank {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            background: url("@{images}/icon/ic_tag_o.png") no-repeat;
          }
          .mask {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            .m {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: -1px;
              width: 100%;
              height: 100%;
              background-color: #010916;
              .opacity(0.3);
            }
            .handel {
              position: relative;
              z-index: 1;
              display: block;
              width: 100%;
              height: 100%;
              background: url("@{images}/icon/handle.png") no-repeat center;
            }
          }
        }
        .game-body {
          margin-top: 12px;
          .game-title {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            .opacity(0.6);
          }
          .game-intro {
            margin-top: 4px;
            .opacity(0.2);
          }
        }
      }
      .rank-cion {
        float: left;
        width: 20px;
        height: 26px;
        margin-right: 10px;
        background: url("@{images}/icon/ic_tag_db.png") no-repeat;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        text-indent: 0.5px;
      }
      .rank-element {
        padding: 20px 0;
        border-top: 1px solid @white-side-border;
        cursor: pointer;
        .rank-body {
          .rank-img {
            float: left;
            width: 100px;
            height: 46px;
            height: 46px;
          }
          .text {
            font-size: 14px;
            padding: 5px 0;
          }
          .name {
            float: left;
            max-width: 160px;
            margin-left: 10px;
            .ellipsis();
            .opacity(0.6);
          }
          .game-intro {
            margin-top: 4px;
            .opacity(0.2);
          }
          .free {
            color: @red;
            float: right;
            .opacity(0.8);
          }
          &:hover {
            .name {
              .opacity(1);
            }
          }
        }
      }
    }
    .source {
      margin-bottom: -20px;
      .ad-source {
        margin-bottom: 20px;
        border: 1px solid @white-side-border;
        max-height: 256px;
        .source-img {
          position: relative;
          height: 100px;
          border-bottom: 1px solid @white-side-border;
          background-color: @main-bg;
          overflow: hidden;
          img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
          }
        }
        .source-body {
          position: relative;
          padding: 20px 40px 34px 40px;
          .text {
            margin-bottom: 20px;
            font-size: 12px;
            line-height: 14px;
            .name {
              float: left;
              max-width: 165px;
              .opacity(0.6);
              letter-spacing: 0.96px;
              .ellipsis();
            }
            .free {
              float: right;
              color: @red;
              .opacity(0.8);
            }
          }
          .source-more {
            position: absolute;
            width: 48px;
            height: 14px;
            right: 40px;
            bottom: 20px;
            background: url("@{images}/icon/ic_more.png") no-repeat center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .tab-content {
    .ibox-content {
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
  .table {
    overflow: hidden;
    table {
      width: 100%;
      max-width: 100%;
      border-spacing: 0px;
      border-collapse: collapse;
    }
    th {
      display: block;
      text-align: left;
      margin-top: 40px;
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 20px;
      .opacity(.6);
      letter-spacing: 1.12px;
    }
    td {
      display: block;
      vertical-align: middle;
      .opacity(0.6);
      cursor: pointer;
      &:hover {
        .opacity(1);
      }
      p {
        float: left;
      }
      .detail {
        width: 133px;
        margin-right: 20px;
      }
      & + td {
        margin-top: 10px;
      }
      &.name {
        width: 133px;
        max-width: 133px;
        text-align: left;
        .ellipsis();
      }
      &.hover:hover {
        .opacity(1);
      }
    }
  }
  .empty {
    margin-top: 300px;
    font-size: 24px;
    text-align: center;
    .opacity(0.4);
  }
  .letter-space-sm {
    letter-spacing: 0.06px;
  }
}
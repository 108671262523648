@import "variable";

.header-ad{
  width:100%;
  height:120px;
  background:@blue-light;
  .header-img{
    position:relative;
    margin:0 auto;
    width:1180px;
    height:120px;
    img{
      width:100%;
      height:100%;
    }
    .header-off{
      cursor:pointer;
      position:absolute;
      top:0;
      right:0;
      width:26px;
      height:26px;
      background: url("@{images}/ad/OFF.png");
    }
  }
}
.header {
  position: relative;
  width: 100%;
  min-width: 1180px;
  background: @main-color;
  border-bottom: 2px solid @orange;
  .container {
    width: 1180px;
    margin: 0 auto;
    overflow: hidden;
  }
  .bottom {
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    z-index: 1;
    background-image: linear-gradient(-270deg, @red 0%, @orange 100%);
    background: -moz-linear-gradient(-270deg, @red 0%, @orange 100%);
    background: -webkit-gradient(-270deg, @red 0%, @orange 100%);
  }
  .logo {
    padding: 25px 0;
  }
  .logo {
    float: left;
    width: 189px;
    height: 78px;
    background: url("@{images}/icon/logo-game.png") no-repeat 0 center;
  }
  .menu-search {
    float: right;
    overflow: hidden;
  }
  .menu, .search {
    float: left;
  }
  .menu {
    font-weight: 400;
    margin-right: 20px;
    li {
      float: left;
      margin: 20px 0;
      .opacity(0.4);
      a {
        display: block;
        padding: 9px 30px;
        line-height: 20px;
      }
      &:hover, &.active {
        .opacity(1);
      }
    }
  }
  .search {
    position: relative;
    padding: 21px 0;
    input {
      width: 260px;
      padding: 8px 20px;
      line-height: 20px;
      outline: 0;
      border: 0;
      background-color: @blue-light;
      .border-radius(18px);
      color: @white;
      letter-spacing: inherit;
    }
    button {
      position: absolute;
      top: 21px;
      right: 0;
      text-indent: 1000px;
      outline: 0;
      border: 0;
      padding: 10px 20px 10px 0;
      width: 36px;
      height: 36px;
      background: transparent url("@{images}/icon/ic_search.png") no-repeat 0 center;
      cursor: pointer;
    }
  }
}
